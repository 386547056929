<template>
  <div style="height: 100%; width: 100%">
    <div class="orange pa-4 white--text">LET OP: Niet alle verboden zoekgebieden worden weergeven op deze pagina. <span class="font-weight-bold">Vraag 
      altijd toestemming aan de eigenaar van het stuk grond waar je gaat zoeken.</span>
    </div>
    <piepkaart
      :minLoadDataZoom="0"
      :legend="legenda"
      name="Verboden zoekgebieden"
    >
    </piepkaart>
  </div>
</template>
<script>
import Piepkaart from "@/components/Piepkaart.vue";
export default {
  name: "verboden-zoekgebieden",
  components: {
    Piepkaart,
  },
  data() {
    return {
      showInfo: true,
      fab: false,
      legenda: [
        { color: "#FF6666", label: "Gemeentelijke APV" },
        { color: "#FF6666", label: "Rijksmonument" },
        { color: "#FF6666", label: "Beschermd natuurgebied" },
        { color: "#FF6666", label: "Staatsbosbeheer" },
      ],
    };
  },
  methods: {
    styleOptions() {
      return {
        weight: 0.5,
        color: "#fff",
        opacity: 0,
        fillColor: "#DC2626",
        fillOpacity: 0.75,
      };
    },
    rijksmonumentFilter(feature) {
      return feature.properties.namespace === "nlps-rijksmonumenten";
    },
    onAPV(feature, layer) {
      if (feature.properties) {
        layer.bindPopup(
          `
            <div>
              <div style="font-weight: 600">APV verbod</div>
              <div>Gemeente: ${feature.properties.Gemeentena}</div>
            </div>
            `,
          {
            closeButton: false,
            autoPan: false,
          }
        );
      }
    },
    onSBB(feature, layer) {
      if (feature.properties) {
        layer.bindPopup(
          `
            <div>
              <div style="font-weight: 600">Staatsbosbeheer</div>
              <div style="font-style:italic">${feature.properties.Planeenhei}</div>
              <div class="mt-2">
                Staatsbosbeheer geeft geen toestemming voor het gebruik 
                van een metaaldetector of voor magneetvissen in hun gebieden. 
                Je mag ook geen metaaldetector of magneet om te vissen mee het 
                gebied in nemen. Dit om (graaf)schade, verstoring van de 
                archeologische ondergrond, mogelijk gevaarlijke vondsten van 
                munitie uit de WOII, e.d. te voorkomen.
              </div>
            </div>
            `,
          {
            closeButton: false,
            autoPan: false,
            // keepInView: true,
          }
        );
      }
    },
    onRM(feature, layer) {
      if (feature.properties) {
        layer.bindPopup(
          `
            <div>
              <div style="font-weight: 600">Rijksmonument</div>
              <div style="font-style:italic">
                <a href="${feature.properties.ci_citation}" target="_blank" rel="noreferrer">Meer informatie over dit archeologisch rijksmonument</a>
              </div>
              <div class="mt-2">
                Het uit de grond halen van archeologische resten met behulp van 
                metaaldetectie is niet toegestaan op een archeologisch 
                gemeentelijk, provinciaal of rijksmonument.
              </div>
            </div>
            `,
          {
            closeButton: false,
            autoPan: false,
            // keepInView: true,
          }
        );
      }
    },
    onN2K(feature, layer) {
      console.log(feature);
      if (feature.properties) {
        layer.bindPopup(
          `
            <div>
              <div style="font-weight: 600">Natura 2000 gebied</div>
              <div style="font-style:italic">
                ${feature.properties.naam_n2k}
              </div>
              <div class="mt-2">
                Het uit de grond halen van archeologische resten met behulp van 
                metaaldetectie is niet toegestaan op een archeologisch 
                gemeentelijk, provinciaal of rijksmonument.
              </div>
            </div>
            `,
          {
            closeButton: false,
            autoPan: false,
            // keepInView: true,
          }
        );
      }
    },
  },
  mounted() {
    this.$store.commit("showRestrictedAreas", true);
  },
  beforeDestroy() {
    this.$store.commit("showRestrictedAreas", false);
  },
};
</script>
